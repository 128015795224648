import {useRequest} from "ahooks";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Card, Notification, toast} from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import employeeDocumentTypeService from "../../../../services/employeeDocumentTypeService";
import EmployeeDocumentTypeForm from "./_components/EmployeeDocumentTypeForm";

const EmployeeDocumentTypeShow = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [employeeDocumentType, setEmployeeDocumentType] = useState(undefined);
    const {runAsync} = useRequest(employeeDocumentTypeService.fetch, {
        manual: true,
        defaultParams: [id]
    });

    const handleRun = () => {
        runAsync(id).then(res =>
            setEmployeeDocumentType({
                ...res,
                document_category_id: {
                    value: res.document_category.id,
                    label: res.document_category.name,
                },
            })
        );
    };

    useEffect(() => handleRun(), []);

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        description: Yup.string().max(800).notRequired(),
        document_category_id: Yup.object().required(),
        is_expiration_required: Yup.bool().notRequired(),
        can_be_uploaded_by_company: Yup.bool().notRequired(),
        is_visible_only_to_sysadmin: Yup.bool().required(),
        order_column: Yup.number().required(),
    });

    const initialValues = {
        name: employeeDocumentType?.name,
        description: employeeDocumentType?.description,
        document_category_id: employeeDocumentType?.document_category_id,
        is_expiration_required: employeeDocumentType?.is_expiration_required,
        can_be_uploaded_by_company: employeeDocumentType?.can_be_uploaded_by_company,
        is_visible_only_to_sysadmin: employeeDocumentType?.is_visible_only_to_sysadmin,
        order_column: employeeDocumentType?.order_column,
    };

    const handleDelete = () => {
        employeeDocumentTypeService
            .destroy(id)
            .then(res => {
                toast.push(<Notification title='Tipo documenti dipendenti eliminato con successo'
                                         type='success'/>);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message}
                                                   type='danger'/>));
    };

    return (
        <PageContainer>
            {employeeDocumentType && (
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting}) => {
                        employeeDocumentTypeService
                            .update(employeeDocumentType.uuid, {
                                ...values,
                                document_category_id: values.document_category_id.value,
                            })
                            .then(res => {
                                toast.push(<Notification title='Tipo aggiornato con successo'
                                                         type='success'/>);
                                setEmployeeDocumentType({
                                    ...res,
                                    document_category_id: {
                                        value: res.document_category.id,
                                        label: res.document_category.name,
                                    },
                                });
                            })
                            .catch(err => toast.push(<Notification
                                title={err?.response?.data?.message} type='danger'/>))
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({isValid, isSubmitting, values, resetForm}) => (
                        <Form>
                            <Card
                                header={
                                    <PageHeader
                                        title={`Modifica ${employeeDocumentType.name}`}
                                        loading={false}
                                        toolbar={
                                            <>
                                                <CancelButton label={"Annulla"}
                                                              handleClick={() => navigate("../")}/>
                                                <SaveButton loading={isSubmitting}
                                                            disabled={!isValid}/>
                                                <DestroyButton
                                                    message={`Sei sicuro di voler eliminare la tipologia documento ${values?.name}?`}
                                                    handleClick={handleDelete}
                                                />
                                            </>
                                        }
                                    />
                                }
                            >
                                <div className='flex flex-col gap-4'>
                                    <EmployeeDocumentTypeForm/>
                                </div>
                            </Card>
                        </Form>
                    )}
                </Formik>
            )}
        </PageContainer>
    );
};

export default EmployeeDocumentTypeShow;
